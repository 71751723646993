var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createNewGroupPermission, deleteGroupPermissionById, getAllPermissionGroups, getPermissionGroup, updateGroupPermission } from './actions';
var initialState = {
    permissionGroups: { docs: [], meta: {} },
    permissionGroup: {},
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'permissions_groups',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // GET ALL
        builder
            .addCase(getAllPermissionGroups.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllPermissionGroups.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.permissionGroups = action.payload;
        })
            .addCase(getAllPermissionGroups.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getPermissionGroup.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getPermissionGroup.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.permissionGroup = action.payload;
        })
            .addCase(getPermissionGroup.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE
        builder
            .addCase(deleteGroupPermissionById.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteGroupPermissionById.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.permissionGroup = action.payload;
            state.permissionGroups.docs = state.permissionGroups.docs.filter(function (group) { return group._id !== action.meta.arg.id; });
        })
            .addCase(deleteGroupPermissionById.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // CREATE
        builder
            .addCase(createNewGroupPermission.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createNewGroupPermission.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.permissionGroup = action.payload.data;
            state.permissionGroups.docs = __spreadArray([action.payload.data], state.permissionGroups.docs, true);
        })
            .addCase(createNewGroupPermission.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // EDIT
        builder
            .addCase(updateGroupPermission.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateGroupPermission.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.permissionGroup = action.payload.data;
            state.permissionGroups.docs = state.permissionGroups.docs.map(function (group) {
                return group._id === action.payload.data._id ? action.payload.data : group;
            });
        })
            .addCase(updateGroupPermission.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
